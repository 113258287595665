
.mapouter{
    position:relative;
    text-align:right;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){
    .mapouter{
        height:300px;
        width:300px;
    }
    .gmap_canvas{
        height: 300px;
        width: 300px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px){
    .mapouter{
        height:300px;
        width:300px;
    }
    .gmap_canvas{
        height: 300px;
        width: 300px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px){
    .mapouter{
        height:350px;
        width:350px;
    }
    .gmap_canvas{
        height:350px;
        width:350px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1200px){
    .mapouter{
        height:500px;
        width:500px;
    }
    .gmap_canvas{
        height: 500px;
        width: 500px;
    }        
}