/*#background{
    background: no-repeat center center fixed;
    max-width: 100%;
    height: 100%;
    display: flex;
    background-size: cover;
}*/
h1{
    color: white;
}

/*.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){
    h1{
        font-size: 35px ;
    }
    .centered {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px){
    h1{
        font-size: 70px ;
    }
    .centered {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px){
    h1{
        font-size: 120px ;
    }
    .centered {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1200px){
    h1{
        font-size: 150px ;
    }
    .centered {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
  }