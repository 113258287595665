
/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-end;
  opacity: 0.5;
}
/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav NavLink:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.topnav NavLink.active {
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}



@media screen and (max-width: 600px) {
  .topnav a.icon {
    float: right;
    display: block;
  }
  h1{
    margin-top: 150px;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  h1{
    margin-top: 150px;
  }
}